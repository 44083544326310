import React from "react";
import "./school.css";

import factory from "./img_factory/factor.png";
import useZustandStore from "../../zustandStore";

const App = () => {
  const { language, languageData } = useZustandStore();
  const strings = languageData[language];
  return (
    <>
      <div className="container">
        <div className="text">
          <h2
            style={{
              color: "white",
              marginBottom: "2rem",
              textAlign: "center",
            }}
          >
            {strings.factory}
          </h2>
          <p>{strings.factoryText}</p>
        </div>
        <div className="image">
          <img src={factory} alt="Resim 1" />
        </div>
      </div>
    </>
  );
};

export default App;
