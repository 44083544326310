import React from "react";

import "./school.css";
import carimg2 from "./card_img/2.png";
import useZustandStore from "../../zustandStore";
const App = () => {
  const { language, languageData } = useZustandStore();
  const strings = languageData[language];
  return (
    <>
      <div className="container">
        <div className="text">
          <h2
            style={{
              color: "white",
              marginBottom: "2rem",
              textAlign: "center",
            }}
          >
            {strings.critical}
          </h2>

          <h3 style={{ color: "white", marginBottom: "2rem" }}>
            {strings.criticalTitle}
          </h3>
          <p>{strings.criticalText}</p>
        </div>
        <div className="image">
          <img src={carimg2} alt="Resim 1" />
        </div>
      </div>
    </>
  );
};

export default App;
