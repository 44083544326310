import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { AiOutlineHome } from "react-icons/ai";

import "./ProjectPage.css";
import { SingleProject } from "../../components";
import { ThemeContext } from "../../contexts/ThemeContext";
// import { projectsData } from "../../data/projectsData";
import { headerData } from "../../data/headerData";

import useZustandStore from "../../zustandStore";

import two from "../../assets/svg/projects/voiceFlow.png";

import facial from "../../assets/svg/projects/FACE.png";
import emotion from "../../assets/svg/projects/emotion.png";
import access from "../../assets/svg/projects/access.png";
import admin from "../../assets/svg/projects/asistN.png";

import central from "../../assets/svg/projects/central.png";
import customalgoritims from "../../assets/svg/projects/custom-algoritims.png";
import custom from "../../assets/svg/projects/custom.png";
import dubbing from "../../assets/svg/projects/dubbing.png";
import electrical from "../../assets/svg/projects/electrical.png";
import emergency from "../../assets/svg/projects/emergency.png";
import fire from "../../assets/svg/projects/fire.png";
import HSE from "../../assets/svg/projects/HSE.png";
import Enhancement from "../../assets/svg/projects/Image Enhancement.png";
import languageImage from "../../assets/svg/projects/language.png";
import plate from "../../assets/svg/projects/license.png";
import ocr from "../../assets/svg/projects/ocr.png";
import personalized from "../../assets/svg/projects/personalized.png";
import restricted from "../../assets/svg/projects/restirected.png";
import smoke from "../../assets/svg/projects/smoke.png";
import speecto from "../../assets/svg/projects/speech-to.png";
import virtual from "../../assets/svg/projects/Virtual-Turnstile.png";
import adminmanagemenet from "../../assets/svg/projects/admin.png";
import smoken from "../../assets/svg/projects/smoken0.png";
import llma from "../../assets/svg/projects/LLMpng.png";
import kain from "../../assets/svg/projects/kain.jpg";
import miner from "../../assets/svg/projects/miner.png";
import info from "../../assets/svg/projects/info.png";

function ProjectPage() {
  const [search, setSearch] = useState("");
  const { theme } = useContext(ThemeContext);

  const { language, languageData } = useZustandStore();
  const strings = languageData[language];

  const projectsData = [
    {
      id: 1,
      projectName: strings.projectsKain,
      projectDesc: strings.projectsKainText,
      tags: [],
      code: "",
      demo: "",
      image: kain,
    },
    {
      id: 2,
      projectName: strings.projectsVoiceFlow,
      projectDesc: strings.projectsVoiceFlowText,
      tags: [],
      code: "",
      demo: "",
      image: two,
    },
    {
      id: 3,
      projectName: strings.projectsDocuMiner,
      projectDesc: strings.projectsDocuMinerText,
      tags: [],
      code: "",
      demo: "",
      image: miner,
    },
    {
      id: 29,
      projectName: strings.projectsInfoAssist,
      projectDesc: strings.projectsInfoAssistText,
      tags: [],
      code: "",
      demo: "",
      image: info,
    },
    {
      id: 4,
      projectName: strings.projectsFace,
      projectDesc: strings.projectsFaceText,
      tags: [],
      code: "",
      demo: "",
      image: facial,
    },
    ,
    {
      id: 5,
      projectName: strings.projectsLLM,
      projectDesc: strings.projectsLLMText,
      tags: [],
      code: "",
      demo: "",
      image: llma,
    },
    ,
    {
      id: 6,
      projectName: strings.projectsCustomer,
      projectDesc: strings.projectsCustomerText,
      tags: [],
      code: "",
      demo: "",
      image: customalgoritims,
    },
    ,
    {
      id: 7,
      projectName: strings.projectsEmotion,
      projectDesc: strings.projectsEmotionText,
      tags: [],
      code: "",
      demo: "",
      image: emotion,
    },
    ,
    {
      id: 8,
      projectName: strings.projectsDigital,
      projectDesc: strings.projectsDigitalText,
      tags: [],
      code: "",
      demo: "",
      image: admin,
    },
    ,
    {
      id: 9,
      projectName: strings.projectsRestricted,
      projectDesc: strings.projectsRestrictedText,
      tags: [],
      code: "",
      demo: "",
      image: access,
    },
    ,
    {
      id: 10,
      projectName: strings.projectsImage,
      projectDesc: strings.projectsImageText,
      tags: [],
      code: "",
      demo: "",
      image: Enhancement,
    },

    {
      id: 11,
      projectName: strings.projectsOcr,
      projectDesc: strings.projectsOcrText,
      tags: [],
      code: "",
      demo: "",
      image: ocr,
    },
    {
      id: 12,
      projectName: strings.projectsSpeech,
      projectDesc: strings.projectsSpeechText,
      tags: [],
      code: "",
      demo: "",
      image: speecto,
    },
    {
      id: 13,
      projectName: strings.projectsLanguage,
      projectDesc: strings.projectsLanguageText,
      tags: [],
      code: "",
      demo: "",
      image: languageImage,
    },
    {
      id: 14,
      projectName: strings.projectsDubbing,
      projectDesc: strings.projectsDubbingText,
      tags: [],
      code: "",
      demo: "",
      image: dubbing,
    },
    {
      id: 15,
      projectName: strings.projectsLicense,
      projectDesc: strings.projectsLicenseText,
      tags: [],
      code: "",
      demo: "",
      image: plate,
    },
    {
      id: 16,
      projectName: strings.projectsRestrictedArea,
      projectDesc: strings.projectsRestrictedAreaText,
      tags: [],
      code: "",
      demo: "",
      image: restricted,
    },
    {
      id: 17,
      projectName: strings.projectsSmoking,
      projectDesc: strings.projectsSmokingText,
      tags: [],
      code: "",
      demo: "",
      image: smoke,
    },
    {
      id: 18,
      projectName: strings.projectsCustom,
      projectDesc: strings.projectsCustomText,
      tags: [],
      code: "",
      demo: "",
      image: custom,
    },
    {
      id: 19,
      projectName: strings.projectsVirtual,
      projectDesc: strings.projectsVirtualText,
      tags: [],
      code: "",
      demo: "",
      image: virtual,
    },
    {
      id: 20,
      projectName: strings.projectsHse,
      projectDesc: strings.projectsHseText,
      tags: [],
      code: "",
      demo: "",
      image: HSE,
    },
    {
      id: 21,
      projectName: strings.projectsEmergency,
      projectDesc: strings.projectsEmergencyText,
      tags: [],
      code: "",
      demo: "",
      image: emergency,
    },
    {
      id: 22,
      projectName: strings.projectsFire,
      projectDesc: strings.projectsFireText,
      tags: [],
      code: "",
      demo: "",
      image: fire,
    },
    {
      id: 23,
      projectName: strings.projectsSmokeDetection,
      projectDesc: strings.projectsSmokeDetectionText,
      tags: [],
      code: "",
      demo: "",
      image: smoken,
    },
    {
      id: 24,
      projectName: strings.projectsElectrical,
      projectDesc: strings.projectsElectricalText,
      tags: [],
      code: "",
      demo: "",
      image: electrical,
    },
    {
      id: 25,
      projectName: strings.projectsField,
      projectDesc: strings.projectsFieldText,
      tags: [],
      code: "",
      demo: "",
      image: customalgoritims,
    },
    {
      id: 26,
      projectName: strings.projectsPersonalized,
      projectDesc: strings.projectsPersonalizedText,
      tags: [],
      code: "",
      demo: "",
      image: personalized,
    },
    {
      id: 27,
      projectName: strings.projectsPersonalizedAdmin,
      projectDesc: strings.projectsPersonalizedAdminText,
      tags: [],
      code: "",
      demo: "",
      image: adminmanagemenet,
    },
    {
      id: 28,
      projectName: strings.projectsPersonalizedCenter,
      projectDesc: strings.projectsPersonalizedCenterText,
      tags: [],
      code: "",
      demo: "",
      image: central,
    },
  ];

  const filteredArticles = projectsData.filter((project) => {
    const content = project.projectName + project.projectDesc + project.tags;
    return content.toLowerCase().includes(search.toLowerCase());
  });

  const useStyles = makeStyles((t) => ({
    search: {
      color: theme.tertiary,
      width: "40%",
      height: "2.75rem",
      outline: "none",
      border: "none",
      borderRadius: "10px",
      padding: "0.95rem 1rem",
      fontFamily: "'Noto Sans TC', sans-serif",
      fontWeight: 500,
      fontSize: "0.9rem",
      backgroundColor: theme.tertiary50,
      boxShadow:
        theme.type === "dark"
          ? "inset 3px 3px 6px #ffffff10, inset -3px -3px 6px #00000060"
          : "inset 3px 3px 6px #ffffffbd, inset -3px -3px 6px #00000030",
      "&::placeholder": {
        color: theme.tertiary80,
      },
      [t.breakpoints.down("sm")]: {
        width: "350px",
      },
    },
    home: {
      color: "white",
      position: "absolute",
      top: 25,
      left: 25,
      padding: "7px",
      borderRadius: "50%",
      boxSizing: "content-box",
      fontSize: "2rem",
      cursor: "pointer",
      boxShadow:
        theme.type === "dark"
          ? "3px 3px 6px #ffffff40, -3px -3px 6px #00000050"
          : "3px 3px 6px #ffffff40, -3px -3px 6px #00000050",
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        color: theme.tertiary,
        transform: "scale(1.1)",
      },
      [t.breakpoints.down("sm")]: {
        fontSize: "1.8rem",
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className="projectPage" style={{ backgroundColor: theme.secondary }}>
      <Helmet>
        <title>
          {headerData.name} | {strings.projectsTitle}
        </title>
      </Helmet>
      <div
        className="projectPage-header"
        style={{ backgroundColor: "#222F35" }}
      >
        <Link to="/">
          <AiOutlineHome className={classes.home} />
        </Link>
        <h1 style={{ color: "white" }}>{strings.projectsTitle}</h1>
      </div>
      <div className="projectPage-container">
        <div className="projectPage-search">
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={strings.searchProject}
            className={classes.search}
          />
        </div>
        <div className="project-container">
          <Grid
            className="project-grid"
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            {filteredArticles.map((project) => (
              <SingleProject
                theme={theme}
                key={project.id}
                id={project.id}
                name={project.projectName}
                desc={project.projectDesc}
                tags={project.tags}
                code={project.code}
                demo={project.demo}
                image={project.image}
              />
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default ProjectPage;
