import React from "react";

import "./school.css";
import carimg3 from "./card_img/traffic.png";
import useZustandStore from "../../zustandStore";
const App = () => {
  const { language, languageData } = useZustandStore();
  const strings = languageData[language];
  return (
    <>
      <div className="container">
        <div className="text">
          <h2
            style={{
              color: "white",
              marginBottom: "2rem",
              textAlign: "center",
            }}
          >
            {strings.traffic}
          </h2>
          <h3 style={{ color: "white", marginBottom: "2rem" }}>
            {strings.trafficTitle1}
          </h3>

          <p>{strings.trafficText1}</p>

          <h3
            style={{ color: "white", marginBottom: "2rem", marginTop: "2rem" }}
          >
            {strings.trafficTitle2}
          </h3>

          <p>{strings.trafficText2}</p>
        </div>
        <div className="image">
          <img src={carimg3} alt="Resim 1" />
        </div>
      </div>
    </>
  );
};

export default App;
