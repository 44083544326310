import React from "react";
import { Helmet } from "react-helmet";

import {
  Navbar,
  Footer,
  Landing,
  About,
  Skills,
  Solutions,
  Blog,
  Header,
  Experience,
  Contacts,
  Projects,
  Services,
  Achievement,
} from "../../components";
import { headerData } from "../../data/headerData";

function Main() {
  return (
    <div>
      <Helmet>
        <title>{headerData.name} </title>
      </Helmet>

      <Navbar />
      <Header />
      <Solutions />
      {/* <Landing /> */}
      <About />

      {/* <Experience /> */}
      <Projects />
      <Skills />
      {/* <Achievement /> */}
      <Services />

      {/* <Blog /> */}
      <Contacts />
      <Footer />
    </div>
  );
}

export default Main;
