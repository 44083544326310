import React, { useContext } from "react";
import "./About.css";
import { ThemeContext } from "../../contexts/ThemeContext";

import useZustandStore from "../../zustandStore";
function About() {
  const { language, languageData } = useZustandStore();
  const strings = languageData[language];

  const { theme } = useContext(ThemeContext);
  return (
    <div
      className="about"
      id="about"
      style={{ backgroundColor: theme.secondary }}
    >
      <div className="line-styling">
        <div
          className="style-circle"
          style={{ backgroundColor: theme.primary }}
        ></div>
        <div
          className="style-circle"
          style={{ backgroundColor: theme.primary }}
        ></div>
        <div
          className="style-line"
          style={{ backgroundColor: theme.primary }}
        ></div>
      </div>
      <div className="about-body">
        <div className="about-description">
          <h2 style={{ color: theme.primary }}>{strings.aboutTitle}</h2>

          <p>{strings.aboutText1}</p>
          <p>{strings.aboutText2}</p>
          <p>{strings.aboutText3}</p>
          <p>{strings.aboutText4}</p>
          <p>{strings.aboutText5}</p>
          <p>{strings.aboutText6}</p>
          <p>{strings.aboutText7}</p>
        </div>

        {/* <div className="about-img">
                    <img 
                        src={aboutData.image === 1 ? team : team}  
                        alt="" 
                    />
                </div> */}
      </div>
    </div>
  );
}

export default About;
