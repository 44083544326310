import React from "react";
import styled from "styled-components";
import ParticlesComponent from "./ParticlesComponent";

import TypeWriter from "typewriter-effect";
import useZustandStore from "../../zustandStore";
const HeaderContent = () => {
  const { language, languageData } = useZustandStore();
  const strings = languageData[language];
  return (
    <HeaderContentStyled>
      <div className="left-content">
        <div className="left-text-container">
          <h2 data-aos="zoom-in-right">{strings.headerText1}</h2>

          <p className="white">{strings.headerText2}</p>
          <p
            style={{
              marginTop: "3rem",
              color: "white",
              cursor: "auto",
              fontSize: "18pt",
              userSelect: "none",
            }}
          >
            <TypeWriter
              options={{
                autoStart: true,
                loop: true,
                delay: 100,
                strings: [
                  strings.headerTyperText1,
                  strings.headerTyperText2,
                  strings.headerTyperText3,
                  strings.headerTyperText4,
                  strings.headerTyperText5,
                  strings.headerTyperText6,
                  strings.headerTyperText7,
                  strings.headerTyperText8,
                  strings.headerTyperText9,
                  strings.headerTyperText10,
                  strings.headerTyperText11,
                  strings.headerTyperText12,
                  strings.headerTyperText13,
                ],
              }}
            />
          </p>
        </div>
      </div>
      <div
        class="type-writer-container"
        style={{
          display: "flex",
          zIndex: "+99",
          color: "white",
          textAlign: "center",
          cursor: "auto",
          fontSize: "14pt",
          userSelect: "none",
        }}
      ></div>
      <ParticlesContainer style={{ marginTop: "8rem" }}>
        <ParticlesComponent />
      </ParticlesContainer>
    </HeaderContentStyled>
  );
};

const HeaderContentStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-top: 3rem;

  @media screen and (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .left-content {
    display: flex;
    align-items: center;
    padding-right: 3rem;
    margin-top: 8rem;
    @media screen and (max-width: 700px) {
      margin-top: 0rem;
    }

    h2 {
      font-size: 3rem;
      font-weight: 600;
      color: #fff;

      @media screen and (max-width: 700px) {
        font-size: 3rem;
      }
    }

    .white {
      color: #fff;
      line-height: 1.8rem;
    }
  }
`;

const ParticlesContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`;

export default HeaderContent;
