import React, { useContext, useState, useEffect } from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";

import Fade from "react-reveal/Fade";
import { IoMenuSharp, IoHomeSharp } from "react-icons/io5";
import { HiDocumentText } from "react-icons/hi";
import { BsFillGearFill } from "react-icons/bs";
import { MdPhone } from "react-icons/md";
import { FaUser, FaFolderOpen } from "react-icons/fa";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";
import logom from "./icon.png";
import "./Navbar.css";
import { headerData } from "../../data/headerData";
import { ThemeContext } from "../../contexts/ThemeContext";
import useZustandStore from "../../zustandStore";

function Navbar() {
  const { theme, setHandleDrawer } = useContext(ThemeContext);

  const [open, setOpen] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState("");
  const { language, setLanguage, languageData } = useZustandStore();
  const strings = languageData[language];

  //to remember which select value
  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
    }
  }, []);

  const handleLanguageChange = (selectedLanguage) => {
    setLanguage(selectedLanguage);

    //to remember which select value
    setSelectedLanguage(selectedLanguage);
    localStorage.setItem("selectedLanguage", selectedLanguage);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    setHandleDrawer();
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setHandleDrawer();
  };

  const useStyles = makeStyles((t) => ({
    navMenu: {
      fontSize: "2.5rem",
      color: theme.tertiary,
      cursor: "pointer",
      transform: "translateY(-10px)",
      transition: "color 0.3s",
      "&:hover": {
        color: theme.primary,
      },
      [t.breakpoints.down("sm")]: {
        fontSize: "2.5rem",
      },
      [t.breakpoints.down("xs")]: {
        fontSize: "2rem",
      },
    },
    MuiDrawer: {
      padding: "0em 1.8em",
      width: "14em",
      fontFamily: " var(--primaryFont)",
      fontStyle: " normal",
      fontWeight: " normal",
      fontSize: " 30px",
      background: theme.secondary,
      overflow: "hidden",
      borderTopRightRadius: "40px",
      borderBottomRightRadius: "40px",
      [t.breakpoints.down("sm")]: {
        width: "12em",
      },
    },
    closebtnIcon: {
      fontSize: "2rem",
      fontWeight: "bold",
      cursor: "pointer",
      color: theme.primary,
      position: "absolute",
      right: 40,
      top: 40,
      transition: "color 0.2s",
      "&:hover": {
        color: theme.tertiary,
      },
      [t.breakpoints.down("sm")]: {
        right: 20,
        top: 20,
      },
    },
    drawerItem: {
      margin: "1.4rem auto",
      borderRadius: "78.8418px",
      background: theme.secondary,
      color: theme.primary,
      width: "85%",
      height: "60px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      padding: "0 10px",
      boxSizing: "border-box",
      border: "2px solid",
      borderColor: theme.primary,
      transition: "background-color 0.2s, color 0.2s",
      "&:hover": {
        background: theme.primary,
        color: theme.secondary,
      },
      [t.breakpoints.down("sm")]: {
        width: "100%",
        padding: "0 25px",
        height: "55px",
      },
    },
    drawerLinks: {
      fontFamily: "var(--primaryFont)",
      width: "50%",
      fontSize: "1rem",
      fontWeight: 600,
      [t.breakpoints.down("sm")]: {
        fontSize: "1.125rem",
      },
      "&:hover": {
        color: "var(--accent-color)",
      },
    },
    drawerIcon: {
      fontSize: "1.6rem",
      [t.breakpoints.down("sm")]: {
        fontSize: "1.385rem",
      },
    },
  }));

  const classes = useStyles();

  const shortname = (name) => {
    if (name.length > 12) {
      return name.split(" ")[0];
    } else {
      return name;
    }
  };

  return (
    <div className="navbar">
      <div className="navbar--container">
        <div>
          <img style={{ width: "15rem" }} src={logom} />
        </div>

        <div className="mobile-menu">
          <NavLink
            to="/"
            smooth={true}
            spy="true"
            duration={2000}
            className="nav-link-wrapper"
            style={{ marginLeft: "1rem", color: "#78e478" }}
          >
            <span className={classes.drawerLinks}>{strings.home}</span>
          </NavLink>
          <NavLink
            to="/#solutions"
            smooth={true}
            spy="true"
            duration={2000}
            className="nav-link-wrapper"
            style={{ marginLeft: "1rem", color: "#78e478" }}
          >
            <span className={classes.drawerLinks}>{strings.solutions}</span>
          </NavLink>

          <NavLink
            to="/#about"
            smooth={true}
            spy="true"
            duration={2000}
            style={{ marginLeft: "1rem", color: "#78e478" }}
          >
            <span className={classes.drawerLinks}>{strings.about}</span>
          </NavLink>

          <NavLink
            to="/#projects"
            smooth={true}
            spy="true"
            duration={2000}
            style={{ marginLeft: "1rem", color: "#78e478" }}
          >
            <span className={classes.drawerLinks}>{strings.projects}</span>
          </NavLink>
          <NavLink
            to="/#skills"
            smooth={true}
            spy="true"
            duration={2000}
            className="nav-link-wrapper"
            style={{ marginLeft: "1rem", color: "#78e478" }}
          >
            <span className={classes.drawerLinks}>{strings.skills}</span>
          </NavLink>
          <NavLink
            to="/#services"
            smooth={true}
            spy="true"
            duration={2000}
            style={{ marginLeft: "1rem", color: "#78e478" }}
          >
            <span className={classes.drawerLinks}>{strings.services}</span>
          </NavLink>
          <NavLink
            to="/#contacts"
            smooth={true}
            spy="true"
            duration={2000}
            style={{ marginLeft: "1rem", color: "#78e478" }}
          >
            <span className={classes.drawerLinks}>{strings.contact}</span>
          </NavLink>

          <select
            value={selectedLanguage}
            onChange={(e) => handleLanguageChange(e.target.value)}
            style={{
              height: "30px",
              backgroundColor: "darkgrey",
              color: "white",
              borderRadius: "5px",
              padding: "5px",
              border: "none",
              outline: "none",
              cursor: "pointer",
              marginLeft: "1rem",
            }}
          >
            <option value="default">EN</option>
            <option value="tr">TR</option>
            <option value="ger">DE</option>
            <option value="french">FR</option>
            <option value="spanish">ES</option>
            <option value="arab">AR</option>
          </select>
        </div>

        <div className="mobile-menu2">
          <IoMenuSharp
            className={classes.navMenu}
            onClick={handleDrawerOpen}
            aria-label="Menu"
          />
        </div>
      </div>
      <Drawer
        variant="temporary"
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleDrawerClose();
          } else if (reason !== "escapeKeyDown") {
            handleDrawerClose();
          }
        }}
        anchor="left"
        open={open}
        classes={{ paper: classes.MuiDrawer }}
        className="drawer"
        disableScrollLock={true}
        style={{ zIndex: 999999 }}
      >
        <div className="div-closebtn">
          <CloseIcon
            onClick={handleDrawerClose}
            onKeyDown={(e) => {
              if (e.key === " " || e.key === "Enter") {
                e.preventDefault();
                handleDrawerClose();
              }
            }}
            className={classes.closebtnIcon}
            role="button"
            tabIndex="0"
            aria-label="Close"
          />
        </div>
        <br />

        <div>
          <div className="navLink--container" onClick={handleDrawerClose}>
            <Fade left>
              <NavLink to="/" smooth={true} spy="true" duration={2000}>
                <div className={classes.drawerItem}>
                  <span className={classes.drawerLinks}>{strings.home}</span>
                </div>
              </NavLink>
            </Fade>
            <Fade left>
              <NavLink
                to="/#solutions"
                smooth={true}
                spy="true"
                duration={2000}
              >
                <div className={classes.drawerItem}>
                  <span className={classes.drawerLinks}>
                    {strings.solutions}
                  </span>
                </div>
              </NavLink>
            </Fade>
            <Fade left>
              <NavLink to="/#about" smooth={true} spy="true" duration={2000}>
                <div className={classes.drawerItem}>
                  <span className={classes.drawerLinks}>{strings.about}</span>
                </div>
              </NavLink>
            </Fade>

            <Fade left>
              <NavLink to="/#projects" smooth={true} spy="true" duration={2000}>
                <div className={classes.drawerItem}>
                  <span className={classes.drawerLinks}>
                    {strings.projects}
                  </span>
                </div>
              </NavLink>
            </Fade>
            <Fade left>
              <NavLink to="/#skills" smooth={true} spy="true" duration={2000}>
                <div className={classes.drawerItem}>
                  <span className={classes.drawerLinks}>{strings.skills}</span>
                </div>
              </NavLink>
            </Fade>
            <Fade left>
              <NavLink to="/#services" smooth={true} spy="true" duration={2000}>
                <div className={classes.drawerItem}>
                  <span className={classes.drawerLinks}>
                    {strings.services}
                  </span>
                </div>
              </NavLink>
            </Fade>

            <Fade left>
              <NavLink to="/#contacts" smooth={true} spy="true" duration={2000}>
                <div className={classes.drawerItem}>
                  <span className={classes.drawerLinks}>{strings.contact}</span>
                </div>
              </NavLink>
            </Fade>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginLeft: "-1rem",
            }}
          >
            <Fade left>
              <select
                value={selectedLanguage}
                onChange={(e) => handleLanguageChange(e.target.value)}
                style={{
                  height: "30px",
                  backgroundColor: "darkgrey",
                  color: "white",
                  borderRadius: "5px",
                  padding: "5px",
                  border: "none",
                  outline: "none",
                  cursor: "pointer",
                  marginLeft: "1rem",
                }}
              >
                <option value="default">EN</option>
                <option value="tr">TR</option>
                <option value="ger">DE</option>
                <option value="french">FR</option>
                <option value="spanish">ES</option>
                <option value="arab">AR</option>
              </select>
            </Fade>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default Navbar;
