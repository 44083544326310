import React, { useState } from "react";
import styled from "styled-components";
import bg from "./landing_bg2.png";
import maskedBgImage from "./landing_bg3.png";
import bgVideo from "../../assets/videos/bg_video2.mp4"; // Ensure this path is correct and video format is mp4
import HeaderContent from "./HeaderContent";
import image1 from "./faces/1.jpeg";
import image2 from "./faces/2.jpeg";
import image3 from "./faces/3.jpeg";
import image4 from "./faces/4.jpeg";
import image5 from "./faces/5.jpeg";
import "./Header.css";
import useZustandStore from "../../zustandStore";

const Header = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  const { language, languageData } = useZustandStore();
  const strings = languageData[language];

  return (
    <>
      <HeaderStyled id="header" onMouseMove={handleMouseMove}>
        <div className="header-content">
          <HeaderContent />
        </div>
        <MaskOverlay
          style={{
            "--mouse-x": `${mousePosition.x}px`,
            "--mouse-y": `${mousePosition.y}px`,
          }}
        >
          <video src={bgVideo} autoPlay loop muted playsInline />
        </MaskOverlay>
        <MaskOverlayAltImage
          style={{
            "--mouse-x": `${mousePosition.x}px`,
            "--mouse-y": `${mousePosition.y}px`,
          }}
        />
      </HeaderStyled>

      <div className="container" style={{ alignItems: "center" }}>
        <h1>{strings.headerText3}</h1>
      </div>
      <div className="image-gallery">
        <img src={image1} alt="Image 1" />
        <img src={image2} alt="Image 2" />
        <img src={image3} alt="Image 3" />
        <img src={image4} alt="Image 4" />
        <img src={image5} alt="Image 5" />
      </div>
    </>
  );
};

const HeaderStyled = styled.header`
  position: relative;
  min-height: 100vh;
  width: 100%;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .header-content {
    padding: 0 10rem;
    z-index: 2;
    position: relative;

    @media screen and (max-width: 1347px) {
      padding: 5rem 14rem;
    }
    @media screen and (max-width: 1186px) {
      padding: 5rem 8rem;
    }
    @media screen and (max-width: 990px) {
      padding: 5rem 4rem;
    }
  }
`;

const MaskOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
  mask-image: radial-gradient(
    circle 100px at var(--mouse-x) var(--mouse-y),
    black 100px,
    transparent 200px
  );
  -webkit-mask-image: radial-gradient(
    circle 150px at var(--mouse-x) var(--mouse-y),
    black 150px,
    transparent 300px
  );

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const MaskOverlayAltImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${maskedBgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  pointer-events: none;
  mask-image: radial-gradient(
    circle 100px at var(--mouse-x) var(--mouse-y),
    black 100px,
    transparent 200px
  );
  -webkit-mask-image: radial-gradient(
    circle 150px at var(--mouse-x) var(--mouse-y),
    black 150px,
    transparent 300px
  );
`;

export default Header;
