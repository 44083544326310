import React, { useState } from "react";
import "./Icerik.css";

import { NavHashLink as NavLink } from "react-router-hash-link";
const Card = ({ image, text, number, onClick, content }) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleClick = () => {
    onClick(number);
  };

  return (
    <NavLink
      to="/#navlinks"
      smooth={false}
      spy="false"
      duration={7000}
      style={{ backgroundColor: "rgba(35, 35, 35, 0.4)" }}
    >
      <div
        className={`custom-card ${isHovered ? "hovered" : ""}`}
        onClick={handleClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="custom-card-image-container">
          <img
            src={image}
            alt={text}
            className={`custom-card-image ${isHovered ? "hovered" : ""}`}
          />
          <p className={`custom-card-text ${isHovered ? "hovered" : ""}`}>
            {text}
          </p>
          <div className={`content-overlay ${isHovered ? "show" : ""}`}>
            <p className="content-text">{content}</p>
          </div>
        </div>
      </div>
    </NavLink>
  );
};

export default Card;
