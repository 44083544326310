import React, { useRef, useState, useContext } from "react";
import { ThemeContext } from "../../contexts/ThemeContext";
import "./Contacts.css";
import emailjs from "@emailjs/browser";

import useZustandStore from "../../zustandStore";

const Contacts = () => {
  const { language, languageData } = useZustandStore();
  const strings = languageData[language];
  const form = useRef();
  const [done, setDone] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [message, setMessage] = useState("");

  const [showAlert, setShowAlert] = useState(false);

  const { theme } = useContext(ThemeContext);

  const handleImageClick = () => {
    setShowAlert(showAlert ? false : true);
  };

  const isEmailValid = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (
      userName.trim() === "" ||
      !isEmailValid(userEmail.trim()) ||
      message.trim() === ""
    ) {
      //alert("Lütfen Gerekli Alanları Doldurunuz.");
      setShowAlert(true);
      return;
    }
    setIsLoading(true);
    emailjs
      .sendForm(
        "service_los6pu7",
        "template_5znpyp4",
        form.current,
        "RWH1a8gTZ08e_3r0o"
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsLoading(false);
          setDone(true);
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <div className="contact-form" id="contacts">
        <div className="services-header">
          <h1 style={{ color: theme.primary }}>{strings.contactTitle}</h1>
        </div>
        <div className="form-container" style={{ zIndex: "10" }}>
          <form ref={form} onSubmit={sendEmail}>
            <input
              type="text"
              name="user_name"
              className="user"
              placeholder={strings.userName}
              value={userName}
              onChange={(event) => setUserName(event.target.value)}
            />
            <input
              type="email"
              name="user_email"
              className="user"
              placeholder={strings.userEmail}
              value={userEmail}
              onChange={(event) => setUserEmail(event.target.value)}
            />
            <textarea
              name="message"
              className="user"
              placeholder={strings.message}
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            />
            <div>
              <input
                type="submit"
                value={strings.buttonText}
                className={`button ${done ? "button-disabled" : ""}`}
                disabled={done}
              />
              {isLoading && <div>{strings.sending}</div>}
              {done && strings.thankYou}
            </div>
          </form>
        </div>
        <div className="contact-info">
          <div className="info-card">
            <h3>{strings.email}</h3>
            <p>
              <a href="mailto:info@cozeka.com">{strings.infoMailText}</a>
            </p>
          </div>
          <div className="info-card">
            <h3>{strings.phoneText}</h3>
            <p>
              <a href="tel:+905012120335">{strings.phone}</a>
            </p>
          </div>
          <div className="info-card">
            <h3>{strings.headOffice}</h3>
            <p>{strings.headOfficeText}</p>
          </div>
          <div className="info-card">
            <h3>{strings.techOffice}</h3>
            <p>{strings.techOfficeText}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contacts;
