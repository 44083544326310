import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./Solutions.css";
import schoolplan from "./img_factory/school-plan.png";

import useZustandStore from "../../zustandStore";

const App = () => {
  const { language, languageData } = useZustandStore();
  const strings = languageData[language];
  return (
    <>
      <div className="container">
        <div className="text">
          <h2
            style={{
              color: "white",
              marginBottom: "2rem",
              textAlign: "center",
            }}
          >
            {strings.school}
          </h2>
          <p>{strings.schoolText}</p>
        </div>
        <div className="image">
          <img src={schoolplan} alt="Resim 1" />
        </div>
      </div>
    </>
  );
};

export default App;
