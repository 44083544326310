import React from "react";

import "./school.css";

import carimg1 from "./card_img/1.png";

import useZustandStore from "../../zustandStore";

const App = () => {
  const { language, languageData } = useZustandStore();
  const strings = languageData[language];
  return (
    <>
      <div className="container">
        <div className="text">
          <h2
            style={{
              color: "white",
              marginBottom: "2rem",
              textAlign: "center",
            }}
          >
            {strings.transportation}
          </h2>
          <h3 style={{ color: "white", marginBottom: "2rem" }}>
            {strings.transportationTitle}
          </h3>

          {strings.transportationText}
        </div>
        <div className="image">
          <img src={carimg1} alt="Resim 1" />
        </div>
      </div>
    </>
  );
};

export default App;
