import React from "react";

import "./school.css";

import amusemenet from "./img_Amusement/amusement.png";

import useZustandStore from "../../zustandStore";
const App = () => {
  const { language, languageData } = useZustandStore();
  const strings = languageData[language];
  return (
    <>
      <div className="container">
        <div className="text">
          <h2
            style={{
              color: "white",
              marginBottom: "2rem",
              textAlign: "center",
            }}
          >
            {strings.amusemenet}
          </h2>
          <p>{strings.amusemenetText}</p>
        </div>
        <div className="image">
          <img src={amusemenet} alt="Resim 1" />
        </div>
      </div>
    </>
  );
};

export default App;
