import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./school.css";

import bank from "./bank/7.png";

import useZustandStore from "../../zustandStore";

const App = () => {
  const { language, languageData } = useZustandStore();
  const strings = languageData[language];
  return (
    <>
      <div className="container">
        <div className="text">
          <h2
            style={{
              color: "white",
              marginBottom: "2rem",
              textAlign: "center",
            }}
          >
            {strings.banking}
          </h2>
          <h3 style={{ color: "white", marginBottom: "2rem" }}>
            {strings.bankingTitle1}
          </h3>

          <p>{strings.bankingText1}</p>

          <h3
            style={{ color: "white", marginBottom: "2rem", marginTop: "2rem" }}
          >
            {strings.bankingTitle2}
          </h3>

          <p>{strings.bankingText2}</p>

          <h3
            style={{ color: "white", marginBottom: "2rem", marginTop: "2rem" }}
          >
            {strings.bankingTitle3}
          </h3>

          <p>
            <strong>{strings.bankingTextBold1}</strong>{" "}
            {strings.bankingTextNormal1}
          </p>
          <p>
            <strong>{strings.bankingTextBold2}</strong>{" "}
            {strings.bankingTextNormal2}
          </p>
          <p>
            <strong>{strings.bankingTextBold3}</strong>{" "}
            {strings.bankingTextNormal3}
          </p>
          <p>
            <strong>{strings.bankingTextBold4}</strong>{" "}
            {strings.bankingTextNormal4}
          </p>
          <p>
            <strong>{strings.bankingTextBold5}</strong>{" "}
            {strings.bankingTextNormal5}
          </p>
          <p>
            <strong>{strings.bankingTextBold6}</strong>{" "}
            {strings.bankingTextNormal6}
          </p>
        </div>
        <div className="image">
          <img src={bank} alt="Resim 1" />
        </div>
      </div>
    </>
  );
};

export default App;
