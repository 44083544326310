import "./Card.css";
import React, { useState, useEffect } from "react";
import Card from "./Card";
import factory from "./img_factory/factor.png";
import Hospital from "./healtycare.png";
import gas from "./img_gas/gas-station.png";
import Sayfa1 from "./School";
import Sayfa2 from "./Factory";
import Sayfa3 from "./Villa";
import Sayfa4 from "./Ausemenet";
import Sayfa5 from "./Restaurant";
import Sayfa6 from "./Hospital";
import Sayfa7 from "./Gas";
import Sayfa8 from "./Transportation";
import Sayfa9 from "./Critical";
import Sayfa10 from "./Traffic";
import Sayfa11 from "./Banking";
import Sayfa12 from "./Building";
import factoryentrance from "./img_factory/entrance.png";
import factorysecurity from "./img_factory/security.png";
import factorypublic from "./img_factory/public.png";
import factoryware from "./img_factory/ware.png";
import factoryperimeter from "./img_factory/perimeter.jpg";
import factorycctv from "./img_factory/cctvs.png";
import suchoolpublic from "./img_factory/school2.png";
import schoolplan from "./img_factory/school-plan.png";
import schoolenter from "./img_factory/school-gate.png";

import villaentrance from "./img_vill/viila-entrance.jpg";
import villaoutdoor from "./img_vill/villa-outdoor.jpg";
import villaindoor from "./img_vill/villa-indoor.jpg";
import villa from "./img_vill/villa.png";
import Amusemenet from "./img_Amusement/amusement.png";
import resenter from "./img_Amusement/entrance-door.png";
import reskitchen from "./img_Amusement/kitchen.png";
import rescash from "./img_Amusement/cash-restaurant.png";
import restaurant from "./img_Amusement/restuorant.png";
import office from "./img_Amusement/office.png";
import { NavHashLink as NavLink } from "react-router-hash-link";
import gasrefuel from "./img_gas/refuel.png";
import gasmarket from "./img_gas/aisle.png";
import gascasier from "./img_gas/cashier.png";
import unloading from "./img_gas/unloading.png";

import carimg1 from "./card_img/1.png";
import carimg2 from "./card_img/2.png";
import carimg3 from "./card_img/traffic.png";
import carimg4 from "./card_img/3_.png";
import carimg5 from "./card_img/residential.png";

import transbus from "./trans_img/bus.png";
import airport from "./trans_img/airport3.png";
import harbors from "./trans_img/harbors3.png";
import parking from "./trans_img/parking.png";

import oils from "./oil/1.png";
import energyy from "./oil/2.png";
import telek from "./oil/3.png";

import traffic1 from "./traffic/1.png";
import traffic2 from "./traffic/2.png";
import traffic3 from "./traffic/3.png";
import traffic4 from "./traffic/4.png";
import traffic5 from "./traffic/5.png";
import traffic6 from "./traffic/6.png";

import bank1 from "./bank/1.jpg";
import bank2 from "./bank/2.png";
import bank3 from "./bank/3.jpg";
import bank4 from "./bank/4.png";
import bank5 from "./bank/5.png";
import bank6 from "./bank/6.jpg";

import apartmen1 from "./apertmen/2.jpg";
import apartmen2 from "./apertmen/3.png";
import apartmen3 from "./apertmen/4.png";
import apartmen4 from "./apertmen/5.png";
import apartmen5 from "./apertmen/6.png";

import video1 from "../../assets/videos/bg_video1.mp4";

import useZustandStore from "../../zustandStore";

function Solutions() {
  const [selectedFile, setSelectedFile] = useState(0);

  const { language, languageData } = useZustandStore();
  const strings = languageData[language];

  useEffect(() => {}, [selectedFile]);

  useEffect(() => {
    setSolutionsText(strings.solutions);
  }, [strings]);

  const cards = [
    {
      image: carimg1,
      text: strings.solutionsCard1Text,
      number: 8,
      icerik: strings.solutionsCard1Icerik,
    },
    {
      image: carimg2,
      text: strings.solutionsCard2Text,
      number: 9,
      icerik: strings.solutionsCard2Icerik,
    },
    {
      image: factory,
      text: strings.solutionsCard3Text,
      number: 2,
      icerik: strings.solutionsCard3Icerik,
    },
    {
      image: schoolplan,
      text: strings.solutionsCard4Text,
      number: 1,
      icerik: strings.solutionsCard4Icerik,
    },

    {
      image: carimg3,
      text: strings.solutionsCard5Text,
      number: 10,
      icerik: strings.solutionsCard5Icerik,
    },
    {
      image: carimg4,
      text: strings.solutionsCard6Text,
      number: 11,
      icerik: strings.solutionsCard6Icerik,
    },
    {
      image: carimg5,
      text: strings.solutionsCard7Text,
      number: 12,
      icerik: strings.solutionsCard7Icerik,
    },

    {
      image: villa,
      text: strings.solutionsCard8Text,
      number: 3,
      icerik: strings.solutionsCard8Icerik,
    },
    {
      image: Amusemenet,
      text: strings.solutionsCard9Text,
      number: 4,
      icerik: strings.solutionsCard9Icerik,
    },
    {
      image: restaurant,
      text: strings.solutionsCard10Text,
      number: 5,
      icerik: strings.solutionsCard10Icerik,
    },
    {
      image: Hospital,
      text: strings.solutionsCard11Text,
      number: 6,
      icerik: strings.solutionsCard11Icerik,
    },
    {
      image: gas,
      text: strings.solutionsCard12Text,
      number: 7,
      icerik: strings.solutionsCard12Icerik,
    },
  ];

  const schoolscard = [
    {
      image: schoolenter,
      text: strings.solutionsSchoolCard1Text,
      number: 1,
      icerik: strings.solutionsSchoolCard1Icerik,
    },
    {
      image: factoryperimeter,
      text: strings.solutionsSchoolCard2Text,
      number: 2,
      icerik: strings.solutionsSchoolCard2Icerik,
    },
    {
      image: suchoolpublic,
      text: strings.solutionsSchoolCard3Text,
      number: 3,
      icerik: strings.solutionsSchoolCard3Icerik,
    },
    {
      image: factorycctv,
      text: strings.solutionsSchoolCard4Text,
      number: 3,
      icerik: strings.solutionsSchoolCard4Icerik,
    },
  ];

  const factorycard = [
    {
      image: factoryperimeter,
      text: strings.solutionsFactoryCard1Text,
      number: 1,
      icerik: strings.solutionsFactoryCard1Icerik,
    },
    {
      image: factorysecurity,
      text: strings.solutionsFactoryCard2Text,
      number: 2,
      icerik: strings.solutionsFactoryCard2Icerik,
    },
    {
      image: factoryentrance,
      text: strings.solutionsFactoryCard3Text,
      number: 3,
      icerik: strings.solutionsFactoryCard3Icerik,
    },
    {
      image: factorypublic,
      text: strings.solutionsFactoryCard4Text,
      number: 3,
      icerik: strings.solutionsFactoryCard4Icerik,
    },
    {
      image: factoryware,
      text: strings.solutionsFactoryCard5Text,
      number: 3,
      icerik: strings.solutionsFactoryCard5Icerik,
    },
    {
      image: factorycctv,
      text: strings.solutionsFactoryCard6Text,
      number: 3,
      icerik: strings.solutionsFactoryCard6Icerik,
    },
  ];

  const villacard = [
    {
      image: villaentrance,
      text: strings.solutionsVillaCard1Text,
      number: 3,
      icerik: strings.solutionsVillaCard1Icerik,
    },
    {
      image: villaoutdoor,
      text: strings.solutionsVillaCard2Text,
      number: 3,
      icerik: strings.solutionsVillaCard2Icerik,
    },
    {
      image: villaindoor,
      text: strings.solutionsVillaCard3Text,
      number: 3,
      icerik: strings.solutionsVillaCard3Icerik,
    },
  ];

  const restaurantcard = [
    {
      image: resenter,
      text: strings.solutionsRestaurantCard1Text,
      number: 3,
      icerik: strings.solutionsRestaurantCard1Icerik,
    },
    {
      image: restaurant,
      text: strings.solutionsRestaurantCard2Text,
      number: 3,
      icerik: strings.solutionsRestaurantCard2Icerik,
    },
    {
      image: rescash,
      text: strings.solutionsRestaurantCard3Text,
      number: 3,
      icerik: strings.solutionsRestaurantCard3Icerik,
    },
    {
      image: reskitchen,
      text: strings.solutionsRestaurantCard4Text,
      number: 3,
      icerik: strings.solutionsRestaurantCard4Icerik,
    },
    {
      image: office,
      text: strings.solutionsRestaurantCard5Text,
      number: 3,
      icerik: strings.solutionsRestaurantCard5Icerik,
    },
  ];

  const gasstationcard = [
    {
      image: gasrefuel,
      text: strings.solutionsGasRefuelCardText,
      number: 3,
      icerik: strings.solutionsGasRefuelCardIcerik,
    },
    {
      image: unloading,
      text: strings.solutionsUnloadingCardText,
      number: 3,
      icerik: strings.solutionsUnloadingCardIcerik,
    },
    {
      image: resenter,
      text: strings.solutionsStoreEntranceCardText,
      number: 3,
      icerik: strings.solutionsStoreEntranceCardIcerik,
    },
    {
      image: gasmarket,
      text: strings.solutionsProductAreaCardText,
      number: 3,
      icerik: strings.solutionsProductAreaCardIcerik,
    },
    {
      image: gascasier,
      text: strings.solutionsStoreCashierCardText,
      number: 3,
      icerik: strings.solutionsStoreCashierCardIcerik,
    },
    {
      image: office,
      text: strings.solutionsOfficeCardText,
      number: 3,
      icerik: strings.solutionsOfficeCardIcerik,
    },
  ];

  const transcard = [
    {
      image: transbus,
      text: strings.solutionsTransbusCardText,
      number: 1,
      icerik: strings.solutionsTransbusCardIcerik,
    },
    {
      image: parking,
      text: strings.solutionsParkingCardText,
      number: 2,
      icerik: strings.solutionsParkingCardIcerik,
    },
    {
      image: harbors,
      text: strings.solutionsHarborsCardText,
      number: 3,
      icerik: strings.solutionsHarborsCardIcerik,
    },
    {
      image: airport,
      text: strings.solutionsAirPortsCardText,
      number: 3,
      icerik: strings.solutionsAirPortsCardIcerik,
    },
  ];

  const criticalcard = [
    {
      image: oils,
      text: strings.solutionsOilsCardText,
      number: 1,
      icerik: strings.solutionsOilsCardIcerik,
    },
    {
      image: energyy,
      text: strings.solutionsElectricPowerCardText,
      number: 2,
      icerik: strings.solutionsElectricPowerCardIcerik,
    },
    {
      image: telek,
      text: strings.solutionsTelecommunicationCardText,
      number: 3,
      icerik: strings.solutionsTelecommunicationCardIcerik,
    },
  ];

  const trafficcard = [
    {
      image: traffic1,
      text: strings.solutionsTraffic1CardText,
      number: 1,
      icerik: strings.solutionsTraffic1CardIcerik,
    },
    {
      image: traffic2,
      text: strings.solutionsTraffic2CardText,
      number: 2,
      icerik: strings.solutionsTraffic2CardIcerik,
    },
    {
      image: traffic3,
      text: strings.solutionsTraffic3CardText,
      number: 3,
      icerik: strings.solutionsTraffic3CardIcerik,
    },
    {
      image: traffic4,
      text: strings.solutionsTraffic4CardText,
      number: 1,
      icerik: strings.solutionsTraffic4CardIcerik,
    },
    {
      image: traffic5,
      text: strings.solutionsTraffic5CardText,
      number: 2,
      icerik: strings.solutionsTraffic5CardIcerik,
    },
    {
      image: traffic6,
      text: strings.solutionsTraffic6CardText,
      number: 3,
      icerik: strings.solutionsTraffic6CardIcerik,
    },
  ];

  const bankcard = [
    {
      image: bank1,
      text: strings.solutionsBank1CardText,
      number: 1,
      icerik: strings.solutionsBank1CardIcerik,
    },
    {
      image: bank2,
      text: strings.solutionsBank2CardText,
      number: 2,
      icerik: strings.solutionsBank2CardIcerik,
    },
    {
      image: bank3,
      text: strings.solutionsBank3CardText,
      number: 3,
      icerik: strings.solutionsBank3CardIcerik,
    },
    {
      image: bank4,
      text: strings.solutionsBank4CardText,
      number: 1,
      icerik: strings.solutionsBank4CardIcerik,
    },
    {
      image: bank5,
      text: strings.solutionsBank5CardText,
      number: 2,
      icerik: strings.solutionsBank5CardIcerik,
    },
    {
      image: bank6,
      text: strings.solutionsBank6CardText,
      number: 3,
      icerik: strings.solutionsBank6CardIcerik,
    },
  ];

  const buildingcard = [
    {
      image: apartmen1,
      text: strings.solutionsApartment1CardText,
      number: 1,
      icerik: strings.solutionsApartment1CardIcerik,
    },
    {
      image: apartmen2,
      text: strings.solutionsApartment2CardText,
      number: 2,
      icerik: strings.solutionsApartment2CardIcerik,
    },
    {
      image: apartmen3,
      text: strings.solutionsApartment3CardText,
      number: 3,
      icerik: strings.solutionsApartment3CardIcerik,
    },
    {
      image: apartmen4,
      text: strings.solutionsApartment4CardText,
      number: 1,
      icerik: strings.solutionsApartment4CardIcerik,
    },
    {
      image: apartmen5,
      text: strings.solutionsApartment5CardText,
      number: 2,
      icerik: strings.solutionsApartment5CardIcerik,
    },
  ];

  const [solutionsText, setSolutionsText] = useState(strings.solutions);

  const handleCardClick = (number) => {
    setSelectedFile(number);
    setSolutionsText(strings.solutionsBack);
    console.log("Clicked Card Number:", number);
  };
  const handleCardClickChild = (number) => {
    console.log("Clicked Card Number:", number);
  };
  useEffect(() => {
    console.log("Clicked File:", selectedFile);
  }, [selectedFile]);

  return (
    <>
      <div
        id="solutions"
        style={{ position: "relative", overflow: "hidden", paddingTop: "2rem" }}
      >
        <div id="navlinks" className="navlinks">
          <NavLink
            to="/#solutions"
            smooth={false}
            spy="false"
            duration={7000}
            style={{ backgroundColor: "#232323" }}
          >
            <h2
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                color: "#78e478",
                fontSize: "3rem",
                fontFamily: "var(--primaryFont)",
                cursor: "pointer",
              }}
              onMouseEnter={(e) => {
                e.target.style.color = "#FE8E42";
                e.target.style.textDecoration = "underline";
              }}
              onMouseLeave={(e) => {
                e.target.style.color = "#78e478";
                e.target.style.textDecoration = "none";
              }}
              onClick={() => {
                setSelectedFile(0);
                setSolutionsText(strings.solutions);
              }}
            >
              {solutionsText}
            </h2>
          </NavLink>
        </div>
        <video
          autoPlay
          loop
          muted
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            minWidth: "100%",
            minHeight: "100%",
            width: "auto",
            height: "auto",
            zIndex: "-1",
            transform: "translate(-50%, -50%)",
          }}
        >
          <source src={video1} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            padding: "20px",
            backgroundColor: "rgba(35, 35, 35, 0.2)",
          }}
        >
          {selectedFile === 0 &&
            cards.map((card, index) => (
              <Card
                key={index}
                image={card.image}
                text={card.text}
                number={card.number}
                onClick={handleCardClick}
                content={card.icerik}
              />
            ))}
          {selectedFile === 1 && (
            <>
              <Sayfa1 />

              {schoolscard.map((card, index) => (
                <Card
                  key={index}
                  image={card.image}
                  text={card.text}
                  number={card.number}
                  onClick={handleCardClickChild}
                  content={card.icerik}
                />
              ))}
            </>
          )}

          {selectedFile === 2 && (
            <>
              <Sayfa2 />

              {factorycard.map((card, index) => (
                <Card
                  key={index}
                  image={card.image}
                  text={card.text}
                  number={card.number}
                  onClick={handleCardClickChild}
                  content={card.icerik}
                />
              ))}
            </>
          )}
          {selectedFile === 3 && (
            <>
              <Sayfa3 />

              {villacard.map((card, index) => (
                <Card
                  key={index}
                  image={card.image}
                  text={card.text}
                  number={card.number}
                  onClick={handleCardClickChild}
                  content={card.icerik}
                />
              ))}
            </>
          )}

          {selectedFile === 4 && (
            <>
              <Sayfa4 />
            </>
          )}

          {selectedFile === 5 && (
            <>
              <Sayfa5 />
              {restaurantcard.map((card, index) => (
                <Card
                  key={index}
                  image={card.image}
                  text={card.text}
                  number={card.number}
                  onClick={handleCardClickChild}
                  content={card.icerik}
                />
              ))}
            </>
          )}
          {selectedFile === 6 && (
            <>
              <Sayfa6 />
            </>
          )}

          {selectedFile === 7 && (
            <>
              <Sayfa7 />
              {gasstationcard.map((card, index) => (
                <Card
                  key={index}
                  image={card.image}
                  text={card.text}
                  number={card.number}
                  onClick={handleCardClickChild}
                  content={card.icerik}
                />
              ))}
            </>
          )}

          {selectedFile === 8 && (
            <>
              <Sayfa8 />
              {transcard.map((card, index) => (
                <Card
                  key={index}
                  image={card.image}
                  text={card.text}
                  number={card.number}
                  onClick={handleCardClickChild}
                  content={card.icerik}
                />
              ))}
            </>
          )}

          {selectedFile === 9 && (
            <>
              <Sayfa9 />

              {criticalcard.map((card, index) => (
                <Card
                  key={index}
                  image={card.image}
                  text={card.text}
                  number={card.number}
                  onClick={handleCardClickChild}
                  content={card.icerik}
                />
              ))}
            </>
          )}
          {selectedFile === 10 && (
            <>
              <Sayfa10 />
              {trafficcard.map((card, index) => (
                <Card
                  key={index}
                  image={card.image}
                  text={card.text}
                  number={card.number}
                  onClick={handleCardClickChild}
                  content={card.icerik}
                />
              ))}
            </>
          )}
          {selectedFile === 11 && (
            <>
              <Sayfa11 />
              {bankcard.map((card, index) => (
                <Card
                  key={index}
                  image={card.image}
                  text={card.text}
                  number={card.number}
                  onClick={handleCardClickChild}
                  content={card.icerik}
                />
              ))}
            </>
          )}
          {selectedFile === 12 && (
            <>
              <Sayfa12 />
              {buildingcard.map((card, index) => (
                <Card
                  key={index}
                  image={card.image}
                  text={card.text}
                  number={card.number}
                  onClick={handleCardClickChild}
                  content={card.icerik}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Solutions;
