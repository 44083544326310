import React, { useContext } from "react";

import { ThemeContext } from "../../contexts/ThemeContext";

import goruntu from "../../assets/icons/computer_8392441.png";
import yapayzeka from "../../assets/icons/strategic_12773431.png";
import fullstack from "../../assets/icons/laptop_7418834.png";
import similasyon from "../../assets/icons/similasyon.png";
import game from "../../assets/icons/game.png";
import animasyon from "../../assets/icons/animation.png";

import "./Services.css";
import SingleService from "./SingleService/SingleService";
import useZustandStore from "../../zustandStore";
function Services() {
  const { language, languageData } = useZustandStore();
  const strings = languageData[language];
  const { theme } = useContext(ThemeContext);

  const servicesData = [
    {
      id: 6,
      title: strings.servicesTitle1,
      icon: <img style={{ width: "60px" }} src={yapayzeka} />,
    },
    {
      id: 1,
      title: strings.servicesTitle2,
      icon: <img style={{ width: "50px" }} src={goruntu} />,
    },
    {
      id: 2,
      title: strings.servicesTitle3,
      icon: <img style={{ width: "60px" }} src={fullstack} />,
    },
    {
      id: 3,
      title: strings.servicesTitle4,
      icon: <img style={{ width: "80px" }} src={similasyon} />,
    },
    {
      id: 4,
      title: strings.servicesTitle5,
      icon: <img style={{ width: "50px" }} src={game} />,
    },
    {
      id: 5,
      title: strings.servicesTitle6,
      icon: <img style={{ width: "60px" }} src={animasyon} />,
    },
  ];

  return (
    <>
      {servicesData.length > 0 && (
        <div
          className="services"
          id="services"
          style={{ backgroundColor: theme.secondary }}
        >
          <div className="services-header">
            <h1 style={{ color: theme.primary }}>{strings.servicesTitle}</h1>
          </div>
          <div className="services-body">
            <p style={{ color: theme.tertiary80 }}>{strings.servicesText}</p>
            <div className="services-bodycontainer">
              {servicesData.map((services) => (
                <SingleService
                  key={services.id}
                  id={services.id}
                  title={services.title}
                  icon={services.icon}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Services;
