import React from "react";
import "./school.css";

import gas from "./img_gas/gas-station.png";
import useZustandStore from "../../zustandStore";
const App = () => {
  const { language, languageData } = useZustandStore();
  const strings = languageData[language];
  return (
    <>
      <div className="container">
        <div className="text">
          <h2
            style={{
              color: "white",
              marginBottom: "2rem",
              textAlign: "center",
            }}
          >
            {strings.gasStation}
          </h2>
          <p>{strings.gasStationText}</p>
        </div>
        <div className="image">
          <img src={gas} alt="Resim 1" />
        </div>
      </div>
    </>
  );
};

export default App;
