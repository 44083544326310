import create from "zustand";
import { devtools, persist } from "zustand/middleware";
import stringsTr from "./components/stringsTr.json";
import stringsEng from "./components/stringsEng.json";
import stringsGer from "./components/stringsGer.json";
import stringsFrench from "./components/stringsFrench.json";
import stringsSpanish from "./components/stringsSpanish.json";
import stringsArab from "./components/stringsArab.json";

const zustandStore = (set) => ({
  language: "default",
  setLanguage: (selectedLanguage) => set({ language: selectedLanguage }),
  languageData: {
    default: stringsEng,
    tr: stringsTr,
    ger: stringsGer,
    french: stringsFrench,
    spanish: stringsSpanish,
    arab: stringsArab,
  },
});

// const useZustandStore = create(
//   // to use localstorage
//   devtools(
//     persist(zustandStore, {
//       name: "website-zustand",
//     })
//   )
//   // to not use localstorage
//   // zustandStore
// );

const useZustandStore = create(
  devtools(
    persist(zustandStore, {
      name: "website-zustand",
      getStorage: () => sessionStorage, // Use sessionStorage instead of localStorage
    })
  )
);

export default useZustandStore;
